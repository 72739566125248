import { AddressErrors } from './address-errors.model';

export class AddressModel implements Address {
	address: string;
	address2: string;
	city: string;
	state: string;
	zip: string;

	isValid: boolean; // Is address valid via validator
	errorMessage?: string = ''; // General error message about entire address validation

	errors: AddressErrors; // Errors for each field

	constructor() {
		this.address = '';
		this.address2 = '';
		this.city = '';
		this.state = '';
		this.zip = '';

		this.isValid = false;
		this.errors = new AddressErrors();
	}
}

export interface Address {
	address: string;
	address2: string;
	city: string;
	state: string;
	zip: string;
	isValid?: boolean;
	errorMessage?: string;
	errors: AddressErrors;
}
