import * as React from 'react';
import { BasePageStyles, globalColors } from '../../hooks/styles';
import { useHistory } from 'react-router-dom';
import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Paper,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import dataList from '../../constants/dataList';
import PhoneNumberInput from '../../components/phoneNumberInput';
import ZipCodeInput from '../../components/zipCodeInput';
import config from '../../config';
import { CalculateTotal } from '../../hooks/functions/CalculateTotal';
import { FormatCurrency } from '../../hooks/functions/FormatCurrency';
import ReactRecaptchaConnected from '../../components/reCaptcha';
import Api from '../../redux/lib/api';
import ShippingAddressComponent from './shipping-address.component';
import { Address, AddressModel } from './address.model';
import { v4 as uuidv4 } from 'uuid';
import { OrderShippingItemInterface, OrderShippingItem, toOrderShippingItemClass } from './order-shipping-item.model';

import { RootState } from '../../redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { addOrderShippingItem, updateOrderShippingItem, removeOrderShippingItem } from './order-shipping-list.slice';
import { AddressErrors } from './address-errors.model';

const API_BASE_URL = {
	API_GATEWAY: config.apiGateway,
};

const environment = config.environment; // "dev", "qa" or "prod"

interface PaymentProps {
	orderQuantity: number;
	taxRate: any;
	taxRateLoaded: boolean;
	products: any[];
	isTaxLoading: boolean;
	paymentInputs: any;
	isCaptchaValid: boolean;
	validatedTaxResult: any;
	shippingList: any[];
	createOrder: (payload: { callback: (order: any) => void; order: any }) => void;
	getTaxRate: (payload: { callback: () => void; taxPayload: any }) => void;
	validateAddress: (payload: { callback: (isOpen: boolean) => void; taxPayload: any }) => void;
	validateAddressList: (payload: { callback: () => void; taxPayload: any }) => void;
	clearTaxRate: () => void;
	setSnackbarMessage: (payload: { message: string; type: string; snackbarAutohide?: number }) => void;
	getCcToken: (payload: any) => void;
	setPaymentInputs: (payload: any) => void;
	clearPaymentInputs: () => void;
	setIsCaptchaValid: (isValid: boolean) => void;
	setShippingList: (payload: any) => void;
}

export const PaymentComponent: React.FC<PaymentProps> = props => {
	const classes = BasePageStyles();
	const history = useHistory();
	const NARCAN_PRODUCT_ID = 'NAR062702';
	const NARCAN_PRICE = parseFloat(props.products.find((product: any) => product.id === NARCAN_PRODUCT_ID)?.price || 0);
	const emailRegex = new RegExp(
		/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
	);
	const phoneRegex = new RegExp(/^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/);
	const zipRegex = new RegExp(/^\d{5}$/);

	const orderQtyMax = props.orderQuantity;
	const orderQty = props.orderQuantity;

	
	const isDev = environment === 'dev';

	const initInputs = () => ({
		billingAddress: '',
		billingAddress2: '',
		billingCity: '',
		billingState: '',
		billingZip: '',
		name: '',
		businessName: '',
		email: '',
		phoneNumber: '',
	});

	const requiredInputs = () => {
		if (checked) {
			return ['name', 'email', 'phoneNumber', 'businessName'];
		}
		return [
			'billingAddress',
			'billingCity',
			'billingState',
			'billingZip',
			'name',
			'email',
			'phoneNumber',
			'businessName',
		];
	};

	const [inputs, setInputs] = React.useState({ ...initInputs(), ...props.paymentInputs });
	const [checked, setChecked] = React.useState(props.paymentInputs?.checked || false);
	const [formErrors, setFormErrors] = React.useState<any>({});
	const [submitError, setSubmitError] = React.useState<string>('');
	const [enableSubmit, setEnableSubmit] = React.useState(false);
	const [termsChecked, setTermsChecked] = React.useState(false);

	const [discountCodeInputted, setDiscountCodeInputted] = React.useState<string>('');
	const [discountCodeError, setDiscountCodeError] = React.useState<string>('');
	
	const [confirmedDiscountCodeMessage, setconfirmedDiscountCodeMessage] = React.useState<string>('');
	const [confirmedDiscountCode, setconfirmedDiscountCode] = React.useState<string>('');
	
	// The first shipping address can be set to be the same as the billing address
	const [isShippingAddressSameAsBilling, setIsShippingAddressSameAsBilling] = React.useState<boolean>(false);
	const [employeeEmailInputted, setEmployeeEmailInputted] = React.useState<string>('');
	const [employeeEmailInputVisible, setEmployeeEmailInputVisible] = React.useState<boolean>(false);
	const [isEmployeeEmailRequiredForThisCode, setIsEmployeeEmailRequiredForThisCode] = React.useState<boolean>(false);
	const [discountedUnitPrice, setDiscountedUnitPrice] = React.useState<number|null>(null);
	


	const dispatch = useDispatch();

	React.useEffect(() => {


setInputs({ ...initInputs()});

//ALSO CLEAR VALIDATED TAX RESULT?????

//TODO: clear address form!! RMMM
	// 	// Initialize Shipping List
	// 	if (props.shippingList.length < 1) {
	// 		dispatch(addOrderShippingItem(new OrderShippingItem(narcanProduct, props.orderQuantity)));
	// 	}
	// 	else if (props.shippingList.length === 1) {
	// 		dispatch(removeOrderShippingItem(0));

	// 		const newItem = new OrderShippingItem(narcanProduct, props.orderQuantity);

			
	// 		//need to re-create line items????
	// 		dispatch(addOrderShippingItem(newItem));
	// 	}
	const newItem = new OrderShippingItem(narcanProduct, props.orderQuantity);
	props.setShippingList([newItem])

	}, [dispatch, props.orderQuantity]);

	// const shippingList: Array<OrderShippingItemInterface> = useSelector(
	// 	(state: RootState) => state.orderShippingList.orderShippingList,
	// );

	const getNarcanPrice = () => {
		return discountedUnitPrice ?? NARCAN_PRICE
	}

	const getNarcanUndiscountedPrice = () => {
		return NARCAN_PRICE
	}

	const getNarcanDiscountedAmount = () => {
		if (!discountedUnitPrice) {
			return 0
		}
		else {
			const raw = NARCAN_PRICE - discountedUnitPrice;
			return Math.round(raw*100)/100;
		}
	}

		const narcanProduct = {
		id: NARCAN_PRODUCT_ID,
		name: 'NARCAN Nasal Spray 4 mg',
		brand: 'Narcan',
		affiliation: 'Narcan Shop',
		price: getNarcanPrice(),
	};

	/*
	* send a code to the backend to check if its valid
	* first step which may be the end of it, or second step may be required if it's employee discount
	*/
	const  handleSubmitDiscountCode = async () => {
		let response: any = {};
		const params = {
			discountCode: discountCodeInputted
		};

		try {
			response = await Api.post(`${API_BASE_URL.API_GATEWAY}/validateDiscountCode`, params);
			//response = await Api.post(`http://localhost:3000/validateDiscountCode`, params); 
		}
		catch(e) {
			console.error('error validating code')
			props.setSnackbarMessage({ message: 'error validating code', type: 'error' });
		}

		if (response?.data?.error) {
			props.setSnackbarMessage({ message: response.data.error, type: 'error' });
		}
		else if (response?.data?.isCodeValid) {
			//apply the discount
			props.setSnackbarMessage({ message: 'Discount applied', type: 'success' });
			setDiscountedUnitPrice(response.data.discountedUnitPrice);
		}
		else {
			console.error('error validating code')
			props.setSnackbarMessage({ message: 'error validating code', type: 'error' });
		}
		
		// //on result, update discountCodeError, confirmedDiscountCode, confirmedDiscountCodeMessage, discountCodeInputted
		// response = {
		// 	employeeEmailRequired: true,
		// 	discountedUnitPrice: 25
		// }

		// if (response?.employeeEmailRequired) {
		// 	setIsEmployeeEmailRequiredForThisCode(true);
		// 	setEmployeeEmailInputVisible(true);
		// 	props.setSnackbarMessage({ message: 'This discount code requires a valid FFF employee email. Please enter one below', type: 'success' });
		// }
		// else if (response?.codeValidationComplete) {
		// 	setDiscountedUnitPrice(response?.discountedUnitPrice);
		// 	props.setSnackbarMessage({ message: 'Discount code accepted! Your discount has been applied below', type: 'success' });
		// }
		// else {
		// 	props.setSnackbarMessage({ message: 'Sorry, this discount code is not valid.', type: 'error' });
		// }
	}

	/*
	* validate this is an active employee and that they haven't made use of this discount code before
	*/
	// const handleSubmitEmployeeEmail = async () => {
	// 	let response: any = {};
	// 	const params = {
	// 		discountCode: discountCodeInputted,
	// 		employeeEmail: employeeEmailInputted
	// 	};

	// 	//response = await Api.post(`${API_BASE_URL.API_GATEWAY}/validateEmployeeDiscount`, params);
	// 	response = {
	// 		isValidEmployeeEmail: true,
	// 		discountedUnitPrice: 25,
	// 		employeeHasUsedDiscountBefore: false
	// 	}

	// 	let errorMsg = '';

	// 	if (!(response?.isValidEmployeeEmail)) {
	// 		errorMsg = 'Please enter a valid FFF Enterprises employee email to use this discount code'
	// 	}
	// 	else if (response?.employeeHasUsedDiscountBefore) {
	// 		errorMsg = 'We\'re sorry, you;\'ve already used your employee discount'
	// 	}

	// 	if (errorMsg) {
	// 		props.setSnackbarMessage({ message: errorMsg, type: 'error' });
	// 	}
	// 	else {
	// 		setDiscountedUnitPrice(response?.discountedUnitPrice);
	// 		props.setSnackbarMessage({ message: 'Discount code accepted! Your discount has been applied below', type: 'success' });
	// 	}
	// }

	const getDiscountCodeResultText = () => {
		if (discountCodeError) {
			return discountCodeError;
		}
		else if (confirmedDiscountCode) {
			return confirmedDiscountCodeMessage;
		}
		else {
			return ''
		}
	}

	const getDiscountCodeResultTextColor = () => {
		if (discountCodeError) {
			return 'red';
		}
		else {
			return 'green'
		}
	}



	React.useEffect(() => {
		props.setPaymentInputs({ ...inputs, checked });
	}, [inputs, checked]);


	React.useEffect(() => {
		const w = window as any;
		//Begin Checkout
		w.dataLayer = w.dataLayer || [];
		w.dataLayer.push({
			event: 'begin_checkout',
			ecommerce: {
				tax: props.taxRate || 0,
				items: [
					{
						index: 0,
						price: getNarcanPrice(),
						coupon: '',
						item_id: NARCAN_PRODUCT_ID,
						discount: 0,
						quantity: orderQty,
						item_name: 'NARCAN Nasal Spray 4 mg',
						item_brand: 'Narcan',
						affiliation: 'Narcan Shop',
						item_variant: '',
						item_category: '',
						item_list_name: '',
					},
				],
				value: CalculateTotal(orderQty, getNarcanPrice(), props.taxRate || 0),
				coupon: '',
				currency: 'USD',
			},
		});
	}, []);

	React.useEffect(() => {
		console.log('Validated Tax Result changed: ', props.validatedTaxResult);
		
		if (props.validatedTaxResult && props.validatedTaxResult.length > 0) {
			// Update addresses with validated results
			for (let i = 0; i < props.validatedTaxResult.length; i++) {
				let newShippingItem: OrderShippingItemInterface;
				if (props.validatedTaxResult[i].isValid) {
					const newErrorObj = new AddressErrors();
					newErrorObj.hasError = false; // It's validated so we can assume it has no errors.
					newShippingItem = {
						...props.shippingList[i],
						shippingAddress: {
							city: props.validatedTaxResult[i].city,
							state: props.validatedTaxResult[i].state,
							address: props.validatedTaxResult[i].street,
							address2: props.shippingList[i].shippingAddress.address2,
							zip: props.validatedTaxResult[i].zip,
							isValid: props.validatedTaxResult[i].isValid,
							errors: newErrorObj,
						},
						tax: props.validatedTaxResult[i].tax,
						shipFromWarehouse: props.validatedTaxResult[i].shipFromWarehouse,
					};
				} else {
					newShippingItem = {
						...props.shippingList[i],
						shippingAddress: {
							...props.shippingList[i].shippingAddress,
							isValid: false,
							errorMessage: props.validatedTaxResult[i].errorMessage,
						},
					};
				}
				dispatch(updateOrderShippingItem({ index: i, newShippingItem: newShippingItem })); //???? RMMM

				props.setShippingList([newShippingItem])

				//new handling 11/22 RMM
			}
		}
	}, [props.validatedTaxResult]);

	const routeChange = (path: string) => {
		history.push(path);
	};

	const handleSetInputs = (key: string, value: any) => {
		const newFormErrors = { ...formErrors };

		switch (key) {
			case 'billingAddress':
				if (value === '') {
					newFormErrors[key] = 'Address Line 1 is required';
				} else if (value.length > 25) { //////?? Rmm
					newFormErrors[key] = 'Address length must be less than 25 characters long';
				} else {
					newFormErrors[key] = '';
				}
				break;
			case 'billingAddress2':
				if (value.length > 10) {
					newFormErrors[key] = 'Address line 2 must be less than 10 characters long';
				} else {
					newFormErrors[key] = '';
				}
				break;
		}

		setInputs({ ...inputs, [key]: value });
		setFormErrors(newFormErrors);
		setSubmitError('');
	};

	const formatString = (str: string) => {
		if (!str) return '';
		let formatted = str[0].toUpperCase() + str.slice(1);
		return formatted.replace(/([A-Z])/g, ' $1').trim();
	};

	const onOrderCreated = (order: any) => {
		const w = window as any;
		//Purchase
		w.dataLayer = w.dataLayer || [];
		w.dataLayer.push({
			event: 'purchase',
			ecommerce: {
				tax: props.taxRate || 0,
				items: [
					{
						index: 0,
						price: getNarcanPrice(),
						coupon: '',
						item_id: NARCAN_PRODUCT_ID,
						discount: 0,
						quantity: orderQty,
						item_name: 'NARCAN Nasal Spray 4 mg',
						item_brand: 'Narcan',
						affiliation: 'Narcan Shop',
						item_variant: '',
						item_category: '',
					},
				],
				value: CalculateTotal(orderQty, getNarcanPrice(), props.taxRate || 0),
				coupon: '',
				currency: 'USD',
				shipping: 0,
				transaction_id: order.orderNumber,
			},
		});
		props.clearPaymentInputs();
		routeChange('/order');
	};

	//"transactionToken" is a misnomer, as the txn_id is what Elevon wants
	//to convert from authonly to completed tx later
	const generateOrderList = (transactionToken: string, confirmedDiscountCode: string) => {
		const orderList = [];
		for (let i = 0; i < props.shippingList.length; i++) {

			if (!props.shippingList[i].shipFromWarehouse) {
				console.error('NO SHIP FROM WAREHOUSE:', props.shippingList[i].shipFromWarehouse);
			}

			const orderObj: any = {
				productId: NARCAN_PRODUCT_ID,
				shipAddress1: props.shippingList[i].shippingAddress.address,
				shipAddress2: props.shippingList[i].shippingAddress.address2,
				shipCity: props.shippingList[i].shippingAddress.city,
				shipState: props.shippingList[i].shippingAddress.state,
				shipZip: props.shippingList[i].shippingAddress.zip,
				billingAddress1: inputs.billingAddress,
				billingAddress2: inputs.billingAddress2,
				billingCity: inputs.billingCity,
				billingState: inputs.billingState,
				billingZip: inputs.billingZip,
				shipName: inputs.name,
				billingName: inputs.name,
				businessName: inputs.businessName,
				jobTitle: inputs.jobTitle,
				email: inputs.email,
				phone: inputs.phoneNumber,
				quantity: toOrderShippingItemClass(props.shippingList[i]).getTotalQty(),
				shipFromWarehouse: props.shippingList[i].shipFromWarehouse || '',
				transactionToken: transactionToken
			};

			//when applicable, add the valid discountCode the user entered so it will be saved to the DB when order is created!
			if (confirmedDiscountCode) {
				orderObj.discountCode = confirmedDiscountCode;
			}

			orderList.push(orderObj);
		}
		return orderList;

		//RMM we validate the code for UX courtsey, then send it up on createOrder
				//backend needs to do the work again. this proves the user knows a good code,
				//backend needs to re-confirm the user sent something meaningful,
				//then it can look up the code, get the customerId, look up the customer from SAP and confirm the discounted price for this product
				//ends up woth array of objs @ createOrderFromLines in API service orders.service.ts
	};

	const onPlaceOrder = async () => {
		const errors: any = {};
		for (let [key, value] of Object.entries(inputs)) {
			if (requiredInputs().includes(key) && value === '') {
				errors[key] = `${formatString(key)} is required`;
			}
		}


		if (!emailRegex.test(inputs.email.toLowerCase())) errors.email = 'Invalid email address';

		if (!phoneRegex.test(inputs.phoneNumber)) errors.phoneNumber = 'Invalid phone number';

		if (!checked && !zipRegex.test(inputs.billingZip)) errors.billingZip = 'Invalid zip code';

		if (Object.keys(errors).length > 0) {
			setFormErrors(errors);

			let errorStr = 'Please correct the following error(s): ';

			Object.keys(errors).forEach((key: string) => {
				errorStr += `${errors[key]} \n`
			})

			props.setSnackbarMessage({ message: errorStr, type: 'error' });
			return;
		}

		if (!props.taxRateLoaded) {
			props.setSnackbarMessage({ message: 'Tax rate not loaded', type: 'error' });
			return;
		}

		if (!getNarcanPrice()) {
			props.setSnackbarMessage({ message: 'Product not loaded, please refresh page', type: 'error' });
			return;
		}

		// const order = {
		// 	productId: NARCAN_PRODUCT_ID,
		// 	shipAddress1: inputs.shippingAddress,
		// 	shipAddress2: inputs.shippingAddress2,
		// 	shipCity: inputs.shippingCity,
		// 	shipState: inputs.shippingState,
		// 	shipZip: inputs.shippingZip,
		// 	billingAddress1: checked ? inputs.shippingAddress : inputs.billingAddress,
		// 	billingAddress2: checked ? inputs.shippingAddress2 : inputs.billingAddress2,
		// 	billingCity: checked ? inputs.shippingCity : inputs.billingCity,
		// 	billingState: checked ? inputs.shippingState : inputs.billingState,
		// 	billingZip: checked ? inputs.shippingZip : inputs.billingZip,
		// 	shipName: inputs.name,
		// 	billingName: inputs.name,
		// 	businessName: inputs.businessName,
		// 	email: inputs.email,
		// 	phone: inputs.phoneNumber,
		// 	jobTitle: inputs.jobTitle,
		// 	quantity: orderQty,
		// 	shipFromWarehouse: props.taxRate?.shipFromWarehouse?.warehouse || '',
		// 	transactionToken: undefined,
		// };

		// console.log('creating order:::', order);

		const w = window as any;
		const dc = confirmedDiscountCode || '';

		//Add Payment Info
		w.dataLayer = w.dataLayer || [];
		w.dataLayer.push({
			event: 'add_payment_info',
			ecommerce: { 
				tax: props.taxRate || 0,
				items: [
					{
						index: 0,
						price: getNarcanPrice(),
						coupon: '',
						item_id: NARCAN_PRODUCT_ID,
						discount: 0,
						quantity: orderQty,
						item_name: 'NARCAN Nasal Spray 4 mg',
						item_brand: 'Narcan',
						affiliation: 'Narcan Shop',
						item_variant: '',
						item_category: '',
						item_list_name: '',
					},
				],
				value: CalculateTotal(orderQty, getNarcanPrice(), props.taxRate || 0),
				coupon: '',
				currency: 'USD',
			},
		});

		const createOrderMethod = props.createOrder;

		//option to bypass Elevon in QA, etc
		if (config.skipCCPayment) {
			createOrderMethod({ callback: onOrderCreated, order: generateOrderList('00000000000', dc) });

			//this validates and creates an order on the backend
			//bc we spoofed Elevon, need to fake the lookup of how much the preauth was

			return;
		}

		//if (config.isAutomatedProcess) {
			try {
				console.log('SHIPPING LIST ON SEND TO ELEVON', props.shippingList);
				//1) get the auth token from elevon which allows us to open the Lightbox payment pop-up
				const ccToken = await Api.post(`${API_BASE_URL.API_GATEWAY}/ccPaymentToken`, {
					amount: CalculateTotal(orderQty, getNarcanPrice(), props.taxRate || 0),
					ssl_avs_address: inputs.billingAddress,
					ssl_avs_zip: inputs.billingZip,
					ssl_vendor_id: 'SC963080',
				});

				console.log(`got Elevon token: ${JSON.stringify(ccToken?.data)}`);

				if (ccToken?.data) {
					//2) open popup for user to input CC info for preauth
					openLightbox(ccToken.data);
				}
				else {
					console.error(`error getting Elevon token: No data returned`);
				}
			} 
			catch (e) {
				console.error(`error getting Elevon token: ${JSON.stringify(e)}`);
			}
		// } 
		// else {
		// 	//Manual Process
		// 	//TODO: This process is broken. Should remove all logic related to this flag
		// 	// props.createOrder({ callback: () => routeChange('/order'), order: order });
		// }

		function openLightbox(token: any) {
			console.log('SHIPPING LIST ON SEND TO ELEVON', props.shippingList);

			const paymentFields = {
				ssl_txn_auth_token: token,
				ssl_amount: CalculateTotal(orderQty, getNarcanPrice(), props.taxRate || 0),
				ssl_avs_address: inputs.billingAddress,
				ssl_avs_zip: inputs.billingZip,
				ssl_vendor_id: 'SC963080',
			};

			const callback = {
				onError: function (error: any) {
					console.error('Error creating order:' + JSON.stringify(error));
					props.setSnackbarMessage({ message: `Payment Error: ${JSON.stringify(error)}`, type: 'error' });

					props.setIsCaptchaValid(false);
				},
				onCancelled: function () {
					console.log('Order cancelled by user');

					props.setIsCaptchaValid(false);
				},
				onDeclined: function (response: any) {
					console.log('CC payment declined ' + JSON.stringify(response));

					props.setSnackbarMessage({
						message: `Payment Declined: ${response.ssl_result_message}`,
						type: 'error',
						snackbarAutohide: 30000,
					});

					props.setIsCaptchaValid(false);
				},
				onApproval: function (response: any) {
					console.log('APPROVAL!!' + JSON.stringify(response));
					console.log('with tx uid!!' + response.ssl_txn_id);

					//3) once user's CC preauth is approved by elevon and we store the ssl_txn_id
					// (mis-named as transactionToken), create the order in our Postgres DB
					createOrderMethod({ callback: onOrderCreated, order: generateOrderList(response.ssl_txn_id, dc) });
				},
			};

			const w = window as any;
			w.PayWithConverge.open(paymentFields, callback);

			return false;
		}
	};

	const getPlaceOrderTooltip = () => {
		const addressValidationText = 'shipping address must be validated';
		const recaptchaText = 'reCaptcha must be completed';
		const termsCheckText = 'you must agree to our terms of use';
		let tooltipTexts = [];
		if (!props.taxRateLoaded) tooltipTexts.push(addressValidationText);
		if (!props.isCaptchaValid) tooltipTexts.push(recaptchaText);
		if (!termsChecked) tooltipTexts.push(termsCheckText);
		if (tooltipTexts.length > 0) {
			tooltipTexts[0] = tooltipTexts[0].charAt(0).toUpperCase() + tooltipTexts[0].slice(1);
			tooltipTexts.forEach((text: string, index: number) => {
				if (index === 0) return;
				let newText = ', ';
				if (index === tooltipTexts.length - 1) newText += 'and ';
				tooltipTexts[index] = newText + text;
			});
			tooltipTexts.push(' before placing order.');
		}
		return tooltipTexts.join('');
	};

	const billingAddressCard = () => {
		return (
			<Paper
				className={classes.paymentCard}
				elevation={1}
				sx={{
					gridTemplateColumns: 'repeat(3, 1fr)',
					gridTemplateRows: 'auto',
				}}
			>
				<Box sx={{ gridRow: '1', gridColumn: 'span 3', display: 'inherit', alignItems: 'center' }}>
					<h3>Billing Address</h3>
				</Box>
				<TextField
					label="Address Line 1"
					variant="outlined"
					sx={{ gridRow: '2', gridColumn: 'span 3' }}
					value={checked ? inputs.shippingAddress : inputs.billingAddress}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('billingAddress', event.target.value);
					}}
					error={formErrors.billingAddress ? true : false}
					helperText={formErrors.billingAddress ? formErrors.billingAddress : ''}
					disabled={checked}
					required
				/>
				<TextField
					label="Address Line 2"
					variant="outlined"
					sx={{ gridRow: '3', gridColumn: 'span 3' }}
					value={checked ? inputs.shippingAddress2 : inputs.billingAddress2}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('billingAddress2', event.target.value);
					}}
					error={formErrors.billingAddress2 ? true : false}
					helperText={formErrors.billingAddress2 ? formErrors.billingAddress2 : ''}
					disabled={checked}
				/>
				<TextField
					label="City"
					variant="outlined"
					sx={{ gridRow: '4', gridColumn: '1' }}
					value={checked ? inputs.shippingCity : inputs.billingCity}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('billingCity', event.target.value);
					}}
					error={formErrors.billingCity ? true : false}
					helperText={formErrors.billingCity ? formErrors.billingCity : ''}
					disabled={checked}
					required
				/>
				<Autocomplete
					disablePortal
					options={dataList.StateCodes}
					getOptionLabel={(option: any) => `${option.value}(${option.label})`}
					value={
						inputs.billingState ? dataList.StateCodes.find((code: any) => code.label === inputs.billingState) : null
					}
					onChange={(event, newValue) => {
						handleSetInputs('billingState', newValue?.label || '');
					}}
					disabled={checked}
					renderInput={params => (
						<TextField
							{...params}
							variant="outlined"
							label="State"
							error={formErrors.billingState ? true : false}
							helperText={formErrors.billingState || ''}
							required
						/>
					)}
				/>
				<TextField
					label="Zip Code"
					variant="outlined"
					sx={{ gridRow: '4', gridColumn: '3' }}
					value={checked ? inputs.shippingZip : inputs.billingZip}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('billingZip', event.target.value);
					}}
					error={formErrors.billingZip ? true : false}
					helperText={formErrors.billingZip ? formErrors.billingZip : ''}
					disabled={checked}
					required
					InputProps={{
						inputComponent: ZipCodeInput as any,
					}}
				/>
			</Paper>
		);
	};

	const customerInfoCard = () => {
		return (
			<Paper
				className={classes.paymentCard}
				elevation={1}
				sx={{
					gridTemplateColumns: 'repeat(3, 1fr)',
					gridTemplateRows: 'auto',
				}}
			>
				<Box sx={{ gridRow: '1', gridColumn: 'span 3' }}>
					<h3 style={{ marginTop: '8px' }}>Contact Information</h3>
				</Box>
				<TextField
					label="Full Name"
					variant="outlined"
					sx={{ gridRow: '2', gridColumn: 'span 3' }}
					value={inputs.name}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('name', event.target.value);
					}}
					error={formErrors.name ? true : false}
					helperText={formErrors.name ? formErrors.name : ''}
					required
				/>
				<TextField
					label="Job Title"
					variant="outlined"
					sx={{ gridRow: '3', gridColumn: 'span 3' }}
					value={inputs.jobTitle}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('jobTitle', event.target.value);
					}}
				/>
				<TextField
					label="Business Name"
					variant="outlined"
					sx={{ gridRow: '4', gridColumn: 'span 3' }}
					value={inputs.businessName}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('businessName', event.target.value);
					}}
					helperText={formErrors.businessName ? formErrors.businessName : ''}
					required
				/>
				<TextField
					label="Email"
					variant="outlined"
					sx={{ gridRow: '5', gridColumn: 'span 3' }}
					value={inputs.email}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('email', event.target.value);
					}}
					error={formErrors.email ? true : false}
					helperText={formErrors.email ? formErrors.email : ''}
					required
				/>
				<TextField
					label="Phone Number"
					variant="outlined"
					sx={{ gridRow: '6', gridColumn: 'span 3' }}
					value={inputs.phoneNumber}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('phoneNumber', event.target.value);
					}}
					error={formErrors.phoneNumber ? true : false}
					helperText={formErrors.phoneNumber ? formErrors.phoneNumber : ''}
					required
					InputProps={{
						inputComponent: PhoneNumberInput as any,
					}}
				/>
			</Paper>
		);
	};

	/*
	* component which contrains price calculations *
	*/
	const orderDetailsCard = () => {
		return (
			<>
				{/* "<>" is needed so the new "Grid" will not inherit, but instead be a new root */}
				<Grid container spacing={3}>
					<Grid xs={12} md={6}>
						<Box className={classes.paymentDetails}>
							<h3>Order Details</h3>
							<b>NARCAN&reg; Nasal Spray</b>
							<ul>
								<li>
									<p className={'paymentDetailsLabel'}>
										Qty {orderQty} x ${NARCAN_PRICE}:
									</p>
									<p className={'paymentDetailsData'}>
										${FormatCurrency(CalculateTotal(props.orderQuantity, NARCAN_PRICE))}
									</p>
								</li>


									{Boolean(getNarcanDiscountedAmount() && getNarcanDiscountedAmount() > 0) && 
									<>
										<li>
											<p className={'paymentDetailsLabel'}>Discount amount:</p>
											<p className={'paymentDetailsData'}>
												<b style={{ color: '#E40572' }}>
												-${FormatCurrency( getNarcanDiscountedAmount() * orderQty )}
												</b>
											</p>
										</li>
										<li>
											<p className={'paymentDetailsLabel'}>
												Price after discount:
											</p>
											<p className={'paymentDetailsData'}>
												<b style={{ color: '#E40572' }}>
													${FormatCurrency(CalculateTotal(props.orderQuantity, getNarcanPrice()))}
												</b>
											</p>
										</li>
									</>
							}


								<li>
									<p className={'paymentDetailsLabel'}>Estimated Tax:</p>
									<p className={'paymentDetailsData'}>
										{props.isTaxLoading ? (
											<CircularProgress style={{ color: globalColors.NARCAN_GRAY }} size={15} />
										) : props.taxRateLoaded ? (
											`$${FormatCurrency(props.taxRate || 0)}`
										) : (
											'TBD'
										)}
									</p>
								</li>
								<li>
									<p className={'paymentDetailsLabel'}>Shipping:</p>
									<p className={'paymentDetailsData'}>
										<b style={{ color: '#E40572' }}>FREE</b>
									</p>
								</li>
								<li>
									<p className={'paymentDetailsLabel'}>Total:</p>
									<p className={'paymentDetailsData'}>
										<b>${FormatCurrency(CalculateTotal(props.orderQuantity, getNarcanPrice(), props.taxRate || 0))}</b>
									</p>
								</li>
							</ul>
						</Box>
					</Grid>
					<Grid xs={12} md={6}>
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<ReactRecaptchaConnected />
						</Box>
						<FormControlLabel
							label={
								<span>
									By checking this box, you are agreeing to our{' '}
									<a href={`${config.wordpressUrl}/terms-of-use`}>Terms of Use</a>
								</span>
							}
							className={classes.termsCheck}
							control={
								<Checkbox
									checked={termsChecked}
									aria-role="checkbox"
									onChange={(event, isChecked) => {
										setTermsChecked(isChecked);
									}}
									inputProps={{
										'aria-label': 'controlled',
									}}
								/>
							}
						/>
						<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
							<Tooltip title={getPlaceOrderTooltip()} arrow>
								<span>
									<Button
										onClick={onPlaceOrder}
										disabled={!props.taxRateLoaded || !props.isCaptchaValid || !termsChecked}
										variant="contained"
										size="large"
										aria-role="button"
									>
										Place Order
									</Button>
								</span>
							</Tooltip>
							
						</Box>
					</Grid>
				</Grid>
			</>
		);
	};

	const handleAddShippingAddress = () => {
		dispatch(addOrderShippingItem(new OrderShippingItem(narcanProduct, 1)));
	};

	const sumQty = () => {
		let sum = 0;
		for (let i = 0; i < props.shippingList.length; i++) {
			sum += toOrderShippingItemClass(props.shippingList[i]).getTotalQty();
		}
		return sum;
	};

	const generateTaxPayloadArray = () => {
		const payload = [];

		// const newItem = new OrderShippingItem(narcanProduct, props.orderQuantity);
		// props.setShippingList([newItem]);

	
		for (let i = 0; i < props.shippingList.length; i++) {
		
			props.shippingList[i].productList[0].qty = props.orderQuantity; //hack RMM
			payload.push(toOrderShippingItemClass(props.shippingList[i]).getTaxAndValidationDto());
		}



		return payload;
	};

	const ifBillingIsSameThenGetLatest = () => {
		if (isShippingAddressSameAsBilling) {
			dispatch(
				updateOrderShippingItem({
					index: 0,
					newShippingItem: {
						...props.shippingList[0],
						shippingAddress: {
							address: inputs.billingAddress,
							address2: inputs.billingAddress2,
							city: inputs.billingCity,
							state: inputs.billingState,
							zip: inputs.billingZip,
							errors: new AddressErrors(),
						},
					},
				}),
			);

			props.setShippingList([
				{
						...props.shippingList[0],
						shippingAddress: {
							address: inputs.billingAddress,
							address2: inputs.billingAddress2,
							city: inputs.billingCity,
							state: inputs.billingState,
							zip: inputs.billingZip,
							errors: new AddressErrors(),
						},
				}	

			])
		}
	};

	/*
	* here we gather the info to pass to the backend to calculate tax
	* need to send discounted amount for proper tax calcs
	*/
	const handleValidateAddressList = () => {
		ifBillingIsSameThenGetLatest();
		const payload = generateTaxPayloadArray();

		const finalObj: any = {
			callback: () => {
				console.log('validate address list callback start');
			},
			taxPayload: payload,
		};

		if (getNarcanDiscountedAmount() && getNarcanDiscountedAmount() > 0) {
			finalObj.taxPayload[0].discount = getNarcanDiscountedAmount()
		}


		console.log('Generated Payload for address/tax validation: ', finalObj);
	
		props.validateAddressList(finalObj);
	};

	return (
		<Grid container columnSpacing={2} rowSpacing={4} xs={12} md={8}>
			<Grid xs={12}>
				<h1>Checkout</h1>
			</Grid>
			<Grid xs={12} md={6}>
				{customerInfoCard()}
			</Grid>
			<Grid xs={12} md={6}>
				{billingAddressCard()}
			</Grid>

			{props.shippingList.map((currentShippingItem, index) => (
				<Grid xs={12} key={uuidv4()}>
					<ShippingAddressComponent
						key={uuidv4()}
						listIndex={index}
						listLengthIsGreatThanTwo={props.shippingList.length > 1}
						paymentInputs={props.paymentInputs}
						orderQuantity={toOrderShippingItemClass(currentShippingItem).getTotalQty()}
						enableSubmitOrder={enableSubmit}
						setEnableSubmitOrder={setEnableSubmit}
						product={narcanProduct}
						shippingList={props.shippingList}
						setShippingList={props.setShippingList}
						shippingAddress={currentShippingItem.shippingAddress as AddressModel}
						billingAddress={{
							address: inputs.billingAddress,
							address2: inputs.billingAddress2,
							city: inputs.billingCity,
							state: inputs.billingState,
							zip: inputs.billingZip,
							errors: new AddressErrors(),
						}}
						isSameAsBilling={isShippingAddressSameAsBilling}
						setIsSameAsBilling={setIsShippingAddressSameAsBilling}
					/>
				</Grid>
			))}

			<Grid xs={12}>
				{/*environment !== 'prod' && (
					<Tooltip
						title={
							orderQtyMax < 13 && 'You must have more than 12 items in your cart before shipping to multiple addresses.'
						}
						arrow
					>
						<Button onClick={handleAddShippingAddress} disabled={orderQtyMax < 13}>
							Add Address
						</Button>
					</Tooltip>
				)*/}
				<Grid container justifyContent="flex-end">
					<Button onClick={handleValidateAddressList}>Validate Addresses</Button>
				</Grid>
			</Grid>

			<Grid xs={12}>

			<Paper
				className={classes.paymentCard}
				elevation={1}
				sx={{
					gridTemplateColumns: 'repeat(2, 1fr)',
					gridTemplateRows: 'auto',
				}}
			>
				<Box sx={{ gridRow: '1'}}>
					<h3>Discount Code</h3>
					<div>Enter a discount code if you have one.</div>

					<div style={{
						display: 'flex',
						flexDirection: 'row',
						marginTop: 16
					}}
					>
						<TextField
							label="Discount code"
							variant="outlined"
							sx={{ gridRow: '2', gridColumn: 'span 3' }}
							value={discountCodeInputted}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setDiscountCodeInputted(event.target.value);
							}}
						/>

						<Button onClick={handleSubmitDiscountCode} disabled={!(discountCodeInputted?.length)} style={{
							marginLeft: 16
						}}>
							Validate Code
						</Button>
					</div>


					{/*employeeEmailInputVisible && <div style={{
						display: 'flex',
						flexDirection: 'row',
						marginTop: 16
					}}
					>
						<TextField
						label="Employee email"
						variant="outlined"
						sx={{ gridRow: '2', gridColumn: 'span 3' }}
						value={employeeEmailInputted}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setEmployeeEmailInputted(event.target.value);
						}}
					/>
						<Button onClick={handleSubmitEmployeeEmail} disabled={!(employeeEmailInputted?.length)}style={{
							marginLeft: 16
						}}>
							Validate Employee Email 
						</Button>
					</div> */}


					<div style={{
						color: getDiscountCodeResultTextColor()
					}}>{getDiscountCodeResultText()}</div>
				</Box>
			</Paper>
		
			</Grid>

			<Grid xs={12}>{orderDetailsCard()}</Grid>
			<Grid xs={12}>
				<Grid container justifyContent="flex-end">
					{props.shippingList.length > 1 && (
						<>
							<Typography variant="body1">
								{sumQty()} of {orderQtyMax} allocated
							</Typography>
						</>
					)}
				</Grid>
				<Grid container justifyContent="flex-end">
					{sumQty() < orderQtyMax && (
						<Typography variant="body1" style={{ color: 'red' }}>
							All items must be allocated to a shipping address
						</Typography>
					)}
					{sumQty() > orderQtyMax && (
						<Typography variant="body1" style={{ color: 'red' }}>
							You can only allocate the amount ordered
						</Typography>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};
