import { call, put } from 'redux-saga/effects';
import { OrderActions, UIActions } from '../actions/index';
import { OrderService } from '../services/order.service';

export function* preLoadData(action: ReturnType<typeof UIActions.preLoadData>): any {
    try {
        //Preload list of products
        try {
            const { data } = yield call(OrderService.getInstance().getProduct, action.payload);

             //preload discount code data
            // const dcData = yield call(OrderService.getInstance().getAllDiscountCodes);
            // console.log('got data for DCs', JSON.stringify(dcData?.data));
            
            if(data) {

                yield put(OrderActions.setProduct(data));
                yield put(UIActions.preLoadDataSuccess());
            }
            else throw new Error('Failed to get product');
        } catch (err: any) {
            console.log('Failed to get product. Retrying..', err);
            const { data } = yield call(OrderService.getInstance().getProduct, action.payload);
            if(data){
                yield put(OrderActions.setProduct(data));
                yield put(UIActions.preLoadDataSuccess());
            }
            else throw new Error('Failed to get product');
        }
    } catch (err) {
        console.log('PRELOAD DATA SAGA ERROR:', err)
        yield put(UIActions.setSnackbarMessage({message:'Failed to preload data, please refresh page', type: 'error'}))
    }
}
