/**
 * Keeps track of address errors specific to each field.
 */

import { Address } from './address.model';

const maxAddressLength = 25;
const maxAddress2Length = 10;
const zipRegex = new RegExp(/^\d{5}$/);

export class AddressErrors {
	address: string;
	address2: string;
	city: string;
	state: string;
	zip: string;

	hasError: boolean;

	constructor() {
		this.address = '';
		this.address2 = '';
		this.city = '';
		this.state = '';
		this.zip = '';

		this.hasError = true; // There are required fields.  Therefore we start with an error.
	}

	checkForErrors(key: keyof Omit<Address, 'isValid' | 'errorMessage' | 'errors'>, newValue: string) {
		switch (key) {
			case 'address':
				if (newValue === '') {
					this[key] = 'Address Line 1 is required';
				} else if (newValue.length > maxAddressLength) {
					this[key] = 'Address length must be less than ' + maxAddressLength + ' characters long';
				} else {
					this[key] = '';
				}
				break;
			case 'address2':
				if (newValue.length > maxAddress2Length) {
					this[key] = 'Address line 2 must be less than ' + maxAddress2Length + ' characters long';
				} else {
					this[key] = '';
				}
				break;
			case 'city':
				if (newValue === '') {
					this[key] = 'City is required';
				} else {
					this[key] = '';
				}
				break;
			case 'state':
				if (newValue === '') {
					this[key] = 'State is required';
				} else {
					this[key] = '';
				}
				break;
			case 'zip':
				if (newValue === '') {
					this[key] = 'ZIP Code is required';
				} else if (!zipRegex.test(newValue)) {
					this[key] = 'ZIP Code is invalid';
				} else {
					this[key] = '';
				}
				break;
			default:
				console.error(key, ' is an invalid key for an Address model.');
		}
	}

	checkIfAddressHasErrors() {
		this.hasError = false; // Assume no errors
		for (const [key, value] of Object.entries(this)) {
			if (value !== '') {
				this.hasError = true;
				return; // We have an error, no need to continue.
			}
		}
	}
}
