import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderShippingItemInterface, OrderShippingItem } from './order-shipping-item.model';

interface OrderShippingListState {
	orderShippingList: OrderShippingItemInterface[];
}

const initialState: OrderShippingListState = {
	orderShippingList: [], // Array of order shipping item objects
};

/*
	A "slice" is a collection of Redux reducer logic and actions for a single feature in your app, typically defined together in
	a single file. The name comes from splitting up the root Redux state object into multiple "slices" of state.
	[https://redux.js.org/tutorials/essentials/part-2-app-structure]
*/
const orderShippingListSlice = createSlice({
	name: 'orderShippingList',
	initialState: initialState,
	reducers: {
		addOrderShippingItem: (state, action: PayloadAction<OrderShippingItem>) => {
			// Redux Toolkit allows us to write "mutating" logic in reducers. It
			// doesn't actually mutate the state because it uses the Immer library,
			// which detects changes to a "draft state" and produces a brand new
			// immutable state based off those changes
			// [https://redux.js.org/tutorials/essentials/part-2-app-structure#creating-slice-reducers-and-actions]
			const slCopy: any = state.orderShippingList ? [...state.orderShippingList] : [];
			slCopy.push(action.payload);

			state.orderShippingList = slCopy;
		},
		updateOrderShippingItem: (
			state,
			action: PayloadAction<{ index: number; newShippingItem: OrderShippingItemInterface }>,
		) => {
			const { index, newShippingItem } = action.payload;

			console.log('NEW ITEM', newShippingItem);

			const slCopy: any = state.orderShippingList ? [...state.orderShippingList] : [];
			slCopy[index] = newShippingItem;

			state.orderShippingList = slCopy;

			//?????? rmm
		},
		removeOrderShippingItem: (state, action: PayloadAction<number>) => {
			const slCopy: any = state.orderShippingList ? [...state.orderShippingList] : [];
			slCopy.splice(action.payload, 1);

			state.orderShippingList = slCopy;
		},
	},
});

// Export the generated action creators for use in components
export const { addOrderShippingItem, updateOrderShippingItem, removeOrderShippingItem } =
	orderShippingListSlice.actions;

// Export the slice reducer for use in the store configuration
export default orderShippingListSlice.reducer;
