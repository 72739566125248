import * as React from 'react';
import { BasePageStyles, globalColors } from '../../hooks/styles';
import { useHistory } from 'react-router-dom';
import { Box, Button, FormControl, Grid, Input, InputAdornment, InputLabel, TextField, Tooltip } from '@mui/material';
import { IMAGES_URL } from '../../constants/Images';
import NumberInput from '../../components/numberPicker';
import { CalculateTotal } from '../../hooks/functions/CalculateTotal';

interface ProductProps {
	orderQuantity: number
	products: any[]
	setOrderQuantity: (quantity: number) => void
	setValidatedTaxResult: (newVal: any) => void
	setValidatedAddresses: (newVal: any) => void
	clearTaxRate: () => void
}

const productDescription = <div>
	<h2><strong>Product Description:</strong></h2>

	<p>NARCAN&reg; Nasal Spray was designed to rapidly reverse the effects of a life-threatening opioid emergency. It is used to revive someone during an overdose from many prescriptions pain medication or street drugs such as heroin and is available as an over-the-counter treatment.  </p>

	<h3>Key Features:</h3>

	<ul>
		<li>NARCAN&reg; Nasal Spray is easy to use. No nasal swabs or needles</li>
		<li>Safe to use, even if opioids are not present</li>
		<li>Original prescription strength</li>
		<li>Works quickly, reversing an opioid overdose in minutes</li>
	</ul>
</div>

export const ProductComponent: React.FC<ProductProps> = (props) => {
	const classes = BasePageStyles();
	const history = useHistory(); 
	const NARCAN_PRODUCT_ID = 'NAR062702'
	const NARCAN_PRICE = parseFloat(props.products.find((product: any) => product.id === NARCAN_PRODUCT_ID)?.price || 0);
	const [quantity, setQuantity] = React.useState(props.orderQuantity);
	const [total, setTotal] = React.useState(CalculateTotal(props.orderQuantity, NARCAN_PRICE));
	const [tooltipTitle, setTooltipTitle] = React.useState("Min 12");
	const isFirstRender = React.useRef(true);


	const routeChange = (path: string) =>{ 
		history.push(path);
	}

	const onBuyNow = () => {
		const w = window as any;
		//Add to Cart
		w.dataLayer = w.dataLayer || [];
		w.dataLayer.push({
			"event": "add_to_cart",
			"ecommerce": {
				"items": [{
					"index": 0,
					"price": NARCAN_PRICE,
					"coupon": "",
					"item_id": NARCAN_PRODUCT_ID,
					"discount": 0,
					"quantity": quantity,
					"item_name": "NARCAN Nasal Spray 4 mg",
					"item_brand": "Narcan",
					"affiliation": "Narcan Shop",
					"item_variant": "",
					"item_category": ""
				}],
				"value": CalculateTotal(quantity, NARCAN_PRICE)
	,			"currency": "USD"
			}
		});
		props.setOrderQuantity(quantity)
		routeChange('/payment')
	}

	const isTooltipOpen = () => {
		return !isFirstRender.current && (quantity === 12 || quantity === 2250)
	}

	const onQuantityChange = (newQty: number) => {
		if(newQty < 12) newQty = 12
		if(newQty > 2250) newQty = 2250
		setQuantity(newQty)
		props.setOrderQuantity(newQty)

		console.log('NEW QTY', newQty);

		// props.setValidatedTaxResult([]);
		// props.setValidatedAddresses([]);
		props.clearTaxRate()


		setTotal(CalculateTotal(newQty, NARCAN_PRICE))
		if (newQty === 12) setTooltipTitle("Min 12")
		else if (newQty === 2250) setTooltipTitle("Max 2250")
		if(isFirstRender.current) isFirstRender.current = false;
	}

	React.useEffect(() => {
		//View Item
		const w = window as any;

		w.dataLayer = w.dataLayer || [];
		w.dataLayer.push({
			"event": "view_item",
			"ecommerce": {
				"items": [{
					"index": 0,
					"price": NARCAN_PRICE,
					"coupon": "",
					"item_id": NARCAN_PRODUCT_ID,
					"discount": 0,
					"quantity": quantity,
					"item_name": "NARCAN Nasal Spray 4 mg",
					"item_brand": "Narcan",
					"affiliation": "Narcan Shop",
					"item_variant": "",
					"item_category": ""
				}],
				"value": CalculateTotal(quantity, NARCAN_PRICE),
				"currency": "USD"
			}
		});
	}, []);

	return (
		<Grid container item xs={12} md={8}>
			<Grid item xs={12}>
				<h1>NARCAN&reg; Nasal Spray 4 mg</h1>
				<h2>Emergency Opioid Overdose Treatment, 2 Single-Dose Devices, NDC: 69547-627-02</h2>
			</Grid>
			<Grid item xs={12} md={6}>
				<img src={IMAGES_URL.NARCAN_PRODUCT} style={{maxWidth: '100%'}} alt="Narcan Nasal Spray" />
				<h3 style={{textAlign: 'center', color: globalColors.NARCAN_PINK, fontStyle: 'italic'}}>Use as directed</h3>
			</Grid>
			<Grid item xs={12} md={6}>
				{productDescription}
			</Grid>
			<Grid item xs={0} md={6}/>
			<Grid container item xs={12} md={6} >
				<Grid item xs={6}>
					<Tooltip title={tooltipTitle} open={isTooltipOpen()} PopperProps={{ disablePortal: true }} arrow>
						<NumberInput aria-label="Quantity Input" min={12} max={2250} 
							value={quantity}
							onChange={(event, newValue) => {onQuantityChange(newValue || 0)}}
							onInputChange={(event) => {onQuantityChange(parseInt(event.target.value || '0'))}}
							endAdornment={<InputAdornment position="end" sx={{marginLeft: '-35px', marginRight: '18px'}}>bx</InputAdornment>}
						/>
					</Tooltip>
				</Grid>
				<Grid item xs={6} sx={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
					<Button variant="contained" size='large'
						onClick={onBuyNow}
					>Buy Now</Button>
				</Grid>
				<Grid item xs={12}>
				<p style={{textAlign: 'center', color: globalColors.NARCAN_PINK, fontStyle: 'italic'}}>Minimum order quantity 12 boxes</p>
			</Grid>
			</Grid>
		</Grid>
	)
}