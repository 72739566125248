import { action } from 'typesafe-actions';
import { ORDER } from '../constants/actions';

export const createOrder = (payload: any) => action(ORDER.CREATE_ORDER, payload);
export const getOrderDetails = (payload: any) => action(ORDER.GET_ORDER_DETAILS, payload);
export const setOrderDetails = (payload: any) => action(ORDER.SET_ORDER_DETAILS, payload);
export const setShippingList = (payload: any) => action(ORDER.SET_SHIPPING_LIST, payload);



export const setOrderQuantity = (orderQuantity: number) => action(ORDER.SET_ORDER_QUANTITY, orderQuantity);

export const getTaxRate = (payload: any) => action(ORDER.GET_TAX_RATE, payload);
export const setTaxRate = (payload: any) => action(ORDER.SET_TAX_RATE, payload);
export const setTaxRateLoaded = (payload: any) => action(ORDER.SET_TAX_RATE_LOADED, payload);
export const clearTaxRate = () => action(ORDER.CLEAR_TAX_RATE);
export const showTaxLoader = (isLoading: boolean) => action(ORDER.SHOW_TAX_LOADER, isLoading);

export const validateAddress = (payload: any) => action(ORDER.VALIDATE_ADDRESS, payload);
export const setValidatedAddresses = (payload: any) => action(ORDER.SET_VALIDATED_ADDRESSES, payload);

export const validateAddressList = (payload: any) => action(ORDER.VALIDATE_ADDRESS_LIST, payload);
export const setValidatedTaxResult = (payload: any) => action(ORDER.SET_VALIDATED_TAX_RESULTS, payload);

export const getProduct = (payload: any) => action(ORDER.GET_PRODUCT, payload);
export const setProduct = (payload: any) => action(ORDER.SET_PRODUCT, payload);

export const getCcPaymentToken = (payload: any) => action(ORDER.GET_CC_PAYMENT_TOKEN, payload);
export const setToken = (payload: any) => action(ORDER.SET_TOKEN, payload);

export const setPaymentInputs = (payload: any) => action(ORDER.SET_PAYMENT_INPUTS, payload);
export const clearPaymentInputs = () => action(ORDER.CLEAR_PAYMENT_INPUTS);
